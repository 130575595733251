/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'
import { apiDataPromise } from './apiData';
console.log(apiDataPromise);


const formatTestimonialData = () => {
  const apiTestimonialData = apiDataPromise.field_testimonials_export;
  
  if (!Array.isArray(apiTestimonialData)) {
    console.error('Testimonial data is not in the expected format.');
    return [];
  }

  return apiTestimonialData.map((testimonial, index) => ({
    id: index + 1,
    name: testimonial.name || 'Unknown Author',
    title: testimonial.designation || 'Unknown Title',
    text: testimonial.testimonial || 'Unknown Content',
    image: testimonial.image || 'boy2', // Use the appropriate field for the image
  }));
};

export const testimonialsData = formatTestimonialData();
export const status = apiDataPromise.field_enable_testimonial_export;

