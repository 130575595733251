import { apiDataPromise } from './apiData';
import he from 'he';

console.log(apiDataPromise);
export const contactsData = {
    email: apiDataPromise.mail,
    phone: apiDataPromise.field_phone_number_export,
    address: apiDataPromise.field_address_export ? he.decode(apiDataPromise.field_address_export): '',

    sheetAPI: ''
}