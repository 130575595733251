/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import { FaInternetExplorer,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper } from "react-icons/fa";
 
import { apiDataPromise } from './apiData';
console.log(apiDataPromise);

// Function to format service data
const formatServiceData = () => {
  const apiServiceData = apiDataPromise.field_services_export;
  if (!Array.isArray(apiServiceData)) {
    console.error('Service data is not in the expected format.');
    return [];
  }

  return apiServiceData.map((service, index) => ({
    id: index + 1,
    title: service.service_name|| 'Unknown Title',
    icon: service.icon_services || 'image', // Use the appropriate field for the icon
  }));
};

export const servicesData = formatServiceData();
export const status = apiDataPromise.field_enable_service_section_export;
