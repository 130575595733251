import resume from '../assets/pdf/resume.pdf';
import he from 'he';
import { apiDataPromise } from './apiData';
console.log(apiDataPromise);
let headerData = {
  name: apiDataPromise.username,
  title: 'Front Page',
  desciption: apiDataPromise.field_objective, // Corrected the spelling from "desciption" to "description"
  image: apiDataPromise.field_full_name,
  resumePdf: apiDataPromise.field_cv,
};

const apiData = apiDataPromise;
if (apiData) {
  // Use apiData in this module
  //console.log(apiData);
  headerData = {
    salutation: apiData.field_salutation || '',
    name: apiData.field_full_name ? he.decode(apiData.field_full_name) : '', // Use "field_full_name" from the API response
    title: apiData.field_designation ? he.decode(apiData.field_designation) : '', // Use "field_designation" from the API response
    desciption: apiData.field_objective ? he.decode(apiData.field_objective) : '', // Use "field_objective" from the API response
    image: apiData.baseUrl + apiData.user_picture || '', // Use "user_picture" from the API response
    resumePdf: apiData.field_cv != "" ? apiData.baseUrl + apiData.field_cv : '', // Use "field_cv" from the API response
  };
  // ...
}
export { headerData };
