// apiData.js

// Get the current subdomain from the window location
const subdomain = window.location.hostname.split('.')[0];

// Construct the dynamic API endpoint
const apiEndpoint = `https://my-bo.in/usersjson/${subdomain}`;
const baseUrl = 'https://my-bo.in';
let apiDataPromise = {};
// Fetch data and export it as a constant
async function fetchData() {
  try {
    const response = await fetch(apiEndpoint);

    if (response.status === 200) {
      const data = await response.json();
      apiDataPromise = data[0];
      apiDataPromise.baseUrl = baseUrl;
    } else {
      console.error(`Received a ${response.status} response.`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}

  await fetchData();
export { apiDataPromise };
