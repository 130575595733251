import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import he from 'he';


import { apiDataPromise } from './apiData';

// Function to format projects data
const formatProjectsData = () => {
  const apiProjectsData = apiDataPromise.field_projects_export;
  if (!Array.isArray(apiProjectsData)) {
    console.error('Projects data is not in the expected format.');
    return [];
  }

  return apiProjectsData.map((project, index) => ({
    id: index + 1,
    projectName: project.project_name || 'Unknown Project Name',
    projectDesc: project.project_description ? he.decode(project.project_description).replace(/<br \/>/g, '') : '',
    tags: project.project_tag && project.project_tag.length > 0 ? project.project_tag.map(tag => tag.name) : [],
    code: project.code || '',
    demo: project.demo || '',
    image: project.project_image || '',
    video: project.video || '',
  }));
};

export const projectsData = formatProjectsData();
export const status = apiDataPromise.field_enable_project_section_export;


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/