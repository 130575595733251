import { apiDataPromise } from './apiData';

// Function to format experience data
const formatExperienceData = () => {
  const apiExperienceData = apiDataPromise.field_experience_export;
  if (!Array.isArray(apiExperienceData)) {
    console.error('Experience data is not in the expected format.');
    return [];
  }

  return apiExperienceData.map((experience, index) => ({
    id: index + 1,
    company: experience.company || 'Unknown Company',
    jobtitle: experience.role || 'Unknown Job Title',
    startYear: experience.exp_start_date || 'Unknown Start Year',
    endYear: experience.exp_end_date || 'Unknown End Year',
  }));
};

export const experienceData = formatExperienceData();
export const status = apiDataPromise.field_enable_experience_export;
