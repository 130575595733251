import { apiDataPromise } from './apiData';

// Function to format achievement data
const formatAchievementData = () => {
  const apiAchievementData = apiDataPromise.field_achievement_export;
  if (!Array.isArray(apiAchievementData)) {
    console.error('Achievement data is not in the expected format.');
    return {
      bio: '',
      achievements: [],
    };
  }

  const achievements = apiAchievementData.map((achievement, index) => ({
    id: index + 1,
    title: achievement.certification_type || 'Unknown Title',
    details: achievement.certification_description || 'Unknown Details',
    date: achievement.date || 'Unknown Date',
    field: achievement.certification_type1 || 'Unknown Field',
    image: achievement.icon || 'image', // Use the appropriate field for the image URL
  }));

  const bio = apiDataPromise.field_achievement_description_export || '';
  const status = apiDataPromise.field_enable_achievement_section_export;

  return {
    bio,
    achievements,
    status,
  };
};

export const achievementData = formatAchievementData();
