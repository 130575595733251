import { apiDataPromise } from './apiData';

// Extract the relevant education data from the API response
const educationExport = apiDataPromise.field_education_export;
let educationData = {};
// Check if educationExport is an array
if (Array.isArray(educationExport)) {
  // Map the API education data to your desired format
  console.log(apiDataPromise);
  educationData = educationExport.map((education, index) => ({
    id: index + 1,
    institution: education.school || 'Unknown School',
    course: education.type || 'Unknown Course',
    startYear: education.start_date || 'Unknown Start Year',
    endYear: education.end_date || 'Unknown End Year',
  }));
  
  console.log(educationData);

} else {
  // Handle the case where educationExport is not an array
  console.error('Education data is not in the expected format.');
}
export { educationData };
export const status = apiDataPromise.field_education_check_box_export;
