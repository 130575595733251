import { apiDataPromise } from './apiData';
import he from 'he';

const formatPortfolioData = () => {
  const apiPortfolioData = apiDataPromise.field_portfolio_export;
  
  if (!Array.isArray(apiPortfolioData)) {
    console.error('Portfolio data is not in the expected format.');
    return [];
  }

  return apiPortfolioData.map((portfolioItem, index) => ({
    id: index + 1,
    title: portfolioItem.title || 'Unknown Title',
    description: portfolioItem.description || 'Unknown Description',
    date: portfolioItem.date4 || 'Unknown Date',
    image: portfolioItem.image_port || 'Unknown Image URL',
    video: portfolioItem.youtube_embed ? he.decode(portfolioItem.youtube_embed.replace('854', '100%').replace('480', '300')) : '',
    url: portfolioItem.redirection_url || 'Unknown URL',
  }));
};

export const Portfolio = formatPortfolioData();


// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/