/* eslint-disable */
import { apiDataPromise } from './apiData';
import { 
    greenThemeLight, greenThemeDark, bwThemeLight, bwThemeDark, blueThemeLight, blueThemeDark, redThemeLight, redThemeDark, orangeThemeLight, orangeThemeDark, purpleThemeLight, purpleThemeDark, pinkThemeLight, pinkThemeDark, yellowThemeLight, yellowThemeDark
} from '../theme/theme';

// Define an object to map theme names to theme objects
const themeMap = {
    greenThemeLight,
    greenThemeDark,
    bwThemeLight,
    bwThemeDark,
    blueThemeLight,
    blueThemeDark,
    redThemeLight,
    redThemeDark,
    orangeThemeLight,
    orangeThemeDark,
    purpleThemeLight,
    purpleThemeDark,
    pinkThemeLight,
    pinkThemeDark,
    yellowThemeLight,
    yellowThemeDark
};

// Choose the theme based on apiDataPromise.field_theme
const selectedTheme = themeMap[apiDataPromise.field_theme] || greenThemeLight;

export const themeData = {
    theme: selectedTheme
};
