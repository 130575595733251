import { apiDataPromise } from './apiData';
import he from 'he';
import lawyer_girl from '../assets/svg/about/lawyer_girl.svg';
import lawyer_boy from '../assets/svg/about/lawyer_boy.svg';
import teacher_boy from '../assets/svg/about/teacher_boy.svg';
import teacher_girl from '../assets/svg/about/teacher_girl.svg';
import doctor_girl from '../assets/svg/about/doctor_girl.svg';
import doctor_boy from '../assets/svg/about/doctor_boy.svg';


let aboutImage = "";
if (apiDataPromise.field_designation_for_theme !== "") {
    aboutImage = apiDataPromise.field_designation_for_theme;
    aboutImage = apiDataPromise.field_sex === 'Male' ? aboutImage + "_boy" : aboutImage + "_girl";
    
    switch (aboutImage) {
        case 'lawyer_girl':
            aboutImage = lawyer_girl;
            break;
        case 'lawyer_boy':
            aboutImage = lawyer_boy;
            break;
        case 'teacher_boy':
            aboutImage = teacher_boy;
            break;
        case 'teacher_girl':
            aboutImage = teacher_girl;
            break;
        case 'doctor_girl':
            aboutImage = doctor_girl;
            break;
        case 'doctor_boy':
            aboutImage = doctor_boy;
            break;
        default:
            aboutImage = "";
            break;
    }
}

export const aboutData = {
    title: "Who I am",
    description1: apiDataPromise.field_who_i_am ? he.decode(apiDataPromise.field_who_i_am) : '',
    description2: apiDataPromise.field_who_i_am_para2_export ? he.decode(apiDataPromise.field_who_i_am_para2_export) : '',
    image: apiDataPromise.field_sex === 'Male' ? 1 : 0,
    designation_image: aboutImage || ""
}

export const status = apiDataPromise.field_enable_who_i_am_section_export;
