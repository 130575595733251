import { apiDataPromise } from './apiData';
console.log(apiDataPromise);
export const socialsData = {
    github:  apiDataPromise.field_github,
    facebook: apiDataPromise.field_facebook_export,
    linkedIn: apiDataPromise.field_linkedin,
    instagram: apiDataPromise.field_instagram_export,
    codepen: apiDataPromise.field_codepen_export,
    twitter: apiDataPromise.field_twitter,
    reddit: apiDataPromise.field_reddit_export,
    blogger: apiDataPromise.field_blogger_export,
    medium: apiDataPromise.field_medium_export,
    stackOverflow: apiDataPromise.field_stackoverflow_export,
    gitlab: apiDataPromise.field_gitlab_export,
    youtube: apiDataPromise.field_youtube,
}